<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <span class="title">Add New Broadcast</span>
    </template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="broadcastForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="broadcastCreateForm">
        <div class="row">
          <div class="col-md-12">
            <text-input name="TagName" v-model="submitData.name" label="Name" :validate="'required'" />
          </div>
          <div v-if="!isEmail" class="col-md-12">
            <label class="input-label">Number</label>
            <multiselect v-model="selectedNumber" :options="numbers" :multiple="false" :close-on-select="true"
              :clear-on-select="false" :preserve-search="true" placeholder="Select Number" label="label" track-by="id"
              :show-labels="false">
            </multiselect>
            <small class="help pt-1">Number you are going to use to send broadcast from. Leave this blank and system will use default number related to the contact.</small>
          </div>
          <div class="col-md-12 mt-3">
            <tag-selector v-model="submitData.tags"></tag-selector>
          </div>
          <div v-if="$route.params.id" class="col-md-12 mt-3">
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" name="schedule" id="is_only_unopened" value="1"
                v-model="submitData.is_only_unopened">
              <label for="is_only_unopened">
                <span class="abc-label-text">Link UnOpened <a v-b-tooltip.hover
                    title="This broadcast will be sent to only the contacts who didn't open link in previous broadcast yet"
                    class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
          </div>
          <div class="col-md-12 mb-3 mt-1">
            <div v-if="!loadingCredit && messageCredit.creditAvailable !== null">
              <div v-if="isEmail">
                <div v-if="messageCredit && messageCredit.credit">
                  <span class="text-primary">{{ messageCredit.credit }} contacts are available.<br /></span>
                </div>
                <div v-else>
                  <span class="text-danger">ALERT: No contacts are available for this broadcast!</span>
                </div>
              </div>
              <div v-else>
                <span :class="{
                  'text-danger': messageCredit.creditAvailable < msgCreditUsed,
                  'text-primary':
                    messageCredit.creditAvailable >= msgCreditUsed,
                }">Credits Available: {{ messageCredit.creditAvailable }}</span><br />
                <span>Credit Required For This Broadcast: {{ msgCreditUsed }}</span><br />
                <span v-if="messageCredit.creditAvailable < msgCreditUsed" class="text-danger">ALERT: There are not enough
                  credits available to run a
                  broadcast. Contact
                  <a :href="'mailto:' + agencyEmail" target="_blank"><b class="text-danger">{{ agencyEmail }}</b></a>
                  to upgrade.</span>
              </div>
            </div>
            <atom-spinner v-else slot="loading" :animation-duration="1500" :size="30" color="#3578c6" />
          </div>
          <div class="col-md-12 mt-2 mb-3">
            <div class="control-label my-2">Enable Segments</div>
            <vuestic-switch v-model="submitData.is_schedule" :offcolor=true>
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
          </div>
          <div v-if="submitData.is_schedule" class="col-md-12 mb-2 text-primary">
            IMPORTANT: Max number of contacts for segment is {{ maxSegmentCount }}
          </div>
          <div v-if="submitData.is_schedule" class="col-md-6 mt-4">
            <div class="form-group">
              <div class="input-group">
                <label class="control-label">Number of Contacts</label>
                <text-input type="number" name="number_of_contacts" v-model="submitData.number_of_contacts"
                  :validate="'required'" />
              </div>
            </div>
          </div>
          <div v-if="submitData.is_schedule" class="col-md-6 mt-4">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div class="input-group">
                    <label class="control-label">Duration</label>
                    <text-input type="number" name="duration" v-model="submitData.duration" :validate="'required'" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="input-group">
                    <select v-model="submitData.duration_period" class="form-control">
                      <option value="seconds">seconds</option>
                      <option value="minutes">minutes</option>
                      <option value="hour(s)">hour(s)</option>
                      <option value="day(s)">day(s)</option>
                      <option value="week(s)">week(s)</option>
                      <option value="month(s)">month(s)</option>
                      <option value="year(s)">year(s)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5">
            <vuestic-tabs :names="tabNames" v-model="tabSelected">
              <span :slot="tabNames[0]"> </span>
              <span :slot="tabNames[1]">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="input-group">
                        <label class="control-label">Date</label>
                        <vuestic-date-picker id="scheduled_at_day_picker" v-model="submitData.date"
                          :config="datePickerConfig" />
                        <i class="bar"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="input-group">
                        <label class="control-label">Time</label>
                        <vuestic-date-picker id="scheduled_at_time_picker" v-model="submitData.time"
                          :config="timePickerConfig" />
                        <i class="bar"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <multiselect v-model="timezone" :options="timezones" :multiple="false" :close-on-select="true"
                      :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone" label="label"
                      track-by="label" :preselect-first="true" :show-labels="false">
                    </multiselect>
                  </div>
                </div>
              </span>
            </vuestic-tabs>
          </div>
          <div class="col-md-12">
            <div v-if="isEmail">
              <div class="row">
                <div class="col-md-6">
                  <text-input v-model="submitData.options.from_name" label="From Name" name="fromName"></text-input>
                </div>
                <div class="col-md-6">
                  <text-input v-model="submitData.options.from_email" label="From Email" name="fromEmail"></text-input>
                </div>
                <div class="col-md-12">
                  <text-input v-model="submitData.subject" label="Subject" name="subject"
                    validate="required"></text-input>
                </div>
                <div class="col-md-12">
                  <validation-provider rules="required" v-slot="{}" name="Message">
                    <TextEditorPicker v-on:send="sendMessage" v-model="submitData.message" :showSendButton="false"
                      :loading="loading" :enabledEmoji="true" :enabledTemplate="true" :false="true" ref="texteditor"
                      :enable-test="false" :enable-ai="true" :files="submitData.images" :height="400" @done="handleUploadedImage" @onSubject="handleOnSubject"/>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div v-else>
              <validation-provider rules="required" v-slot="{}" name="Message">
                <TextareaEmojiPicker v-on:send="sendMessage" v-model="submitData.message" :loading="loading"
                  :focused="true" :rows="7" :enabledMms="true" :enabledTemplate="true" ref="textareaEmoji"
                  :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false" :optText="optText"
                  :images="submitData.images" :enable-link="true" :enable-ai="true" @done="handleUploadedImage" />
              </validation-provider>
            </div>
          </div>
          <div v-if="!isEmail" class="col-md-12 mt-3">
            <span class="text-primary font-italic"><b>First message to a contact is appended with the opt-out message that
                is set
                in your setting tab.</b></span>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button type="button" class="btn btn-primary btn-danger mr-2" @click="onBack">
              <span>Back <i class="fa fa-arrow-left"></i></span>
            </button>
            <button type="button" class="btn btn-primary mr-2" :disabled="!newBroadcastValid || invalid"
              style="min-width: 80px;" @click="onClickTest">
              <span>{{ testBtnLabel }}</span>
            </button>
            <button type="button" class="btn btn-primary mr-2" :disabled="!newBroadcastValid || invalid"
              style="min-width: 80px;" @click="onClickDraft">
              <span>{{ draftBtnLabel }}</span>
            </button>
            <button class="btn btn-primary" :disabled="!newBroadcastValid || invalid" style="min-width: 80px;">
              <atom-spinner v-if="broadcastProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto;" />
              <span v-else>{{ confirmLabel }}</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <vuestic-modal :isOpen="isOpenModalTest" @ok="handleTest" @cancel="isOpenModalTest = false" okText="Send"
      cancelText="Cancel" :closeOnOk="false" :processing="testProcessing" okClass="btn btn-primary"
      :okDisabled="!testAddress">
      <span slot="title" class="text-primary font-weight-bold">Test Broadcast</span>
      <div v-if="isEmail">
        <p>Please Input Email Addresss to Send Test Mail.</p>
        <text-input type="email" name="Email" placeholder="Enter email" v-model="testAddress" label="Email" />
      </div>
      <div v-else>
        <p>Please Input Phone Number to Send Test Broadcast.</p>
        <phone-number-input name="Phone Number" v-model="testAddress" label="Phone Number" />
      </div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalConfirm" @ok="handleConfirmation" @cancel="isOpenModalConfirm = false"
      :okText="confirmLabel" cancelText="Cancel" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Confirmation</span>
      <p>When sending out a broadcast messages it is important that you send these messages during the day between <b
          class="text-primary">8am</b> and <b class="text-primary">9pm</b>.</p>
      <p>Click the button below to confirm you want to send or schedule this messages at this time.</p>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import Vue from "vue";
import components from "../../common/tables/comps";
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import TagSelector from "../../common/TagSelector";
import TextareaEmojiPicker from "../../common/TextareaEmojiPicker";
import TextEditorPicker from "../../common/TextEditorPicker";
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
    TagSelector, TextareaEmojiPicker, TextEditorPicker
  },
  data() {
    return {
      title: "",
      datePickerConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
      tabNames: ["Send Now", "Send Later"],
      tabSelected: "Send Now",
      loading: false,
      timezone: null,
      submitDataOrg: {
        id: null,
        name: "",
        tags: [],
        send_at: "now",
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("hh:mm A"),
        timezone: "",
        scheduled_at: "",
        subject: "",
        message: "",
        images: [],
        duration_period: 'seconds',
        is_schedule: false,
        is_only_unopened: false,
        options: {},
        save_draft: false,
      },
      submitData: {
      },
      loadingCredit: false,
      messageCredit: false,
      isOpenModalTest: false,
      testProcessing: false,
      testAddress: '',
      isOpenModalConfirm: false,
      selectedNumber: undefined,
      numbers: [],
    };
  },

  created() {
    this.submitDataOrg.provider = this.$route.query.type ? this.$route.query.type : 'sms'
    this.checkPermission(this.submitDataOrg.provider)

    this.getSendgridOptions()
    this.timezone = this.timezones.find(
      (item) => item.value === moment.tz.guess()
    );
    this.submitData = Vue.util.extend({}, this.submitDataOrg);

    if (this.smsLimit) {
      Vue.$toast.open({
        message: "You have reached messages limit",
        type: "error",
      });
      this.$router.push({ name: "business.broadcast.index", query: { type: this.submitData.provider } });
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "user",
      hasSendgridAvailable: "hasSendgridAvailable",
    }),

    maxSegmentCount() {
      return this.isEmail ? 500 : 3000
    },

    isEmail() {
      return this.submitData.provider === 'email'
    },

    confirmLabel() {
      return this.tabSelected === "Send Now" ? "Send Now" : "Schedule";
    },

    testBtnLabel() {
      return this.isEmail ? "Send Test Email" : "Send Test Message"
    },

    draftBtnLabel() {
      return "Save as Draft"
    },

    timezones() {
      return this.$store.getters["timeZones"];
    },

    optText() {
      return this.$store.getters["auth/optText"];
    },

    maxLength() {
      return 1600 - this.optText.length;
    },

    agencyEmail() {
      return this.user && this.user.agency.email;
    },

    smsLimit() {
      if (
        this.user &&
        this.user.business &&
        this.user.business.messages >= this.user.business.number_of_messages
      )
        return true;
      return false;
    },

    newBroadcastValid() {
      if (this.tabSelected === this.tabNames[0]) {
        return !this.broadcastProcessing;
      } else {
        return (
          this.submitData.date &&
          this.submitData.time &&
          !this.broadcastProcessing
        );
      }
    },

    msgCreditUsed() {
      if (
        this.messageCredit &&
        this.messageCredit.credit &&
        this.submitData.message.length
      ) {
        return (
          this.messageCredit.credit * this.segmentCount
        );
      }
      return 0;
    },

    segmentCount() {
      if (this.isEmail) {
        return 1
      } else {
        const hasImage = !!this.submitData.images.length
        return hasImage ? 3 : this.msgCount(this.submitData.message + "\n\n" + this.optText)
      }
    },

    ...mapState("broadcast", {
      broadcastProcessing: "processing",
    }),
  },

  watch: {
    'submitData.tags': function (val) {
      this.getMessageCredit()
    },

    'submitData.is_only_unopened': function (val) {
      this.getMessageCredit()
    },

    'selectedNumber': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.submitData.install_id = newValue ? newValue.id: null
          this.getMessageCredit()
        }
      }
    },
  },

  mounted() {
    this.loadNumbers()
  },
  methods: {
    loadNumbers() {
      this.loading = true
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        this.loading = false
        let tmp = res.data.filter(item => item.status == 'active' && item.active && item.available_number)
        this.numbers = tmp.map(item => {
          const phoneNumber = parsePhoneNumberFromString(item.available_number)
          return {
            id: item.id,
            label: phoneNumber.formatNational() + (item.website ? ' : ' + item.website : '')
          }
        })
        if (this.$route.params.id) {
          this.getBroadcast()
        } else {
          this.loading = false
        }
      });
    },

    getBroadcast() {
      this.loading = true;
      this.$store
        .dispatch("broadcast/get", this.$route.params.id)
        .then((broadcast) => {
          this.checkPermission(broadcast.provider)
          this.submitData = {
            name: broadcast.name,
            install_id: broadcast.install_id,
            tags: broadcast.tags,
            images: broadcast.images,
            send_at: broadcast.scheduled_at ? "later" : "now",
            scheduled_at: broadcast.scheduled_at,
            subject: broadcast.subject,
            message: broadcast.message,
            processed_at: broadcast.processed_at ? true : false,
            timezone: broadcast.timezone || moment.tz.guess(),
            duration: broadcast.duration,
            number_of_contacts: broadcast.number_of_contacts,
            duration_period: broadcast.duration_period,
            is_schedule: broadcast.is_schedule,
            broadcast_id: this.$route.params.id,
            provider: broadcast.provider,
            options: broadcast.options,
          };

          this.selectedNumber = this.numbers.find(item => item.id === broadcast.install_id)

          if (broadcast.scheduled_at) {
            this.tabSelected = this.tabNames[1];
            this.submitData.date = moment
              .tz(broadcast.scheduled_at, this.submitData.timezone)
              .format("YYYY-MM-DD");
            this.submitData.time = moment
              .tz(broadcast.scheduled_at, this.submitData.timezone)
              .format("HH:mm:00");
          }

          if (this.submitData.timezone)
            this.timezone = this.timezones.find(
              (item) => item.value === this.submitData.timezone
            );

          this.loading = false;
        })
        .catch((err) => {
          this.submitDataOrg = Vue.util.extend({}, this.submitData);
          this.$router.push({ name: "business.broadcast.index", query: { type: this.submitData.provider } });
        });
    },

    checkPermission(provider) {
      if (provider === 'email' && !this.hasSendgridAvailable) {
        Vue.$toast.open({
          message: "Email broadcast is not allowed for this account.",
          type: "error",
        });
        this.submitDataOrg = Vue.util.extend({}, this.submitData);
        this.$router.push({ name: "business.broadcast.index" });
      }
    },

    onBack() {
      this.$router.push({ name: 'business.broadcast.index', query: { type: this.submitData.provider } })
    },

    getSendgridOptions() {
      this.$store.dispatch('businessIntegration/list')
    },

    handleOnSubject(subject) {
      this.submitData.subject = subject
    },

    handleUploadedImage(imgs) {
      this.submitData.images = imgs
    },

    handleConfirmation() {
      this.create()
      this.isOpenModalConfirm = false
    },

    checkBadword() {
      let payload = {
        content: this.submitData.message
      }
      return this.$store.dispatch('auth/checkBadword', payload)
        .then((res) => {
          return Promise.resolve(res.value);
        }).catch((e) => {
          return Promise.resolve(false);
        })
    },

    async onClickTest() {
      let hasBadword = await this.checkBadword()
      if (!hasBadword) {
        this.isOpenModalTest = true
      }
    },

    handleTest() {
      this.testProcessing = true;
      const param = Vue.util.extend({}, this.submitData);
      param.to = this.testAddress
      param.segmentCount = this.segmentCount

      this.$store.dispatch('broadcast/test', param).then((res) => {
        this.isOpenModalTest = false;
        this.testProcessing = false;
      })
      .catch(() => {
        this.testProcessing = false;
      });
    },
    sendMessage() { },

    getMessageCredit() {
      this.loadingCredit = true;
      const ids = this.submitData.tags.map((item) => item.id);
      this.$store
        .dispatch("broadcast/messageCredit", {
          tags: ids,
          is_only_unopened: this.submitData.is_only_unopened,
          broadcast_id: this.$route.params.id,
          provider: this.submitData.provider,
          install_id: this.submitData.install_id
        })
        .then((messageCredit) => {
          this.messageCredit = messageCredit;
          this.loadingCredit = false;
        })
        .catch(() => {
          this.loadingCredit = false;
        });
    },

    async onFormSubmit() {
      let hasBadword = await this.checkBadword()
      if (!hasBadword) {
        this.isOpenModalConfirm = true
      }
    },

    onClickDraft() {
      this.submitData.save_draft = true
      this.create()
    },

    create() {
      const param = Vue.util.extend({}, this.submitData);
      param.send_at = this.tabSelected === "Send Now" ? "now" : "later";
      if (param.send_at === "later") {
        param.timezone = this.timezone.value;
        const timestamp = param.date + " " + param.time;
        param.scheduled_at = moment
          .tz(timestamp, "YYYY-MM-DD hh:mm A", param.timezone)
          .utc()
          .format("YYYY-MM-DD HH:mm:00");
      } else {
        delete param.scheduled_at;
      }
      param.segmentCount = this.segmentCount

      this.$store
        .dispatch("broadcast/create", param)
        .then(() => {
          Vue.$toast.open({
            message: "Broadcast has been created successfully!",
            type: "success",
          });
          this.submitDataOrg = Vue.util.extend({}, this.submitData);
          this.$router.push({ name: "business.broadcast.index", query: { type: this.submitData.provider } });
        })
        .catch((err) => { });
    },
  },
  beforeRouteLeave(to, from, next) {
    let diff = this.difference(this.submitData, this.submitDataOrg)

    if (Object.keys(diff).length) {
      const answer = window.confirm('Are you sure you want to leave without saving?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
};
</script>

<style lang="scss" scoped>
.broadcastCreateForm {
  max-width: 768px;
}

.message-textarea {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  outline: none;
  box-shadow: none;
}

.form-group {
  select {
    width: 100%;
    background: none !important;
    border: .0625rem solid #e7e7e7;
    height: 34px !important;
    border-radius: 0px;
  }
}
</style>
