<template>
  <div class="tag-select-wrapper">
    <label v-if="showLabel" class="input-label has-tool-tip">{{ label }} <a v-if="tooltip" v-b-tooltip.hover :title="tooltip" class="fa fa-question-circle tooltip-icon" /></label>
    <multiselect
      v-model="iValue"
      tag-placeholder="Add this as new tag"
      placeholder="Select or add a tag"
      label="name"
      track-by="id"
      :options="tagList"
      :multiple="true"
      :taggable="true"
      @tag="addTag"
      :vid="vid"
    ></multiselect>
  </div>
</template>

<script>
  import VueTagsInput from '@johmun/vue-tags-input';

  export default {
    components: {
      VueTagsInput,
    },
    name: 'tag-selector',
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
        default: 'Tags'
      },
      disabledAdd: {
        type: Boolean,
        default: false,
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      tooltip: {
        type: String,
        default: '',
      },
    },
    watch: {
    },
    data () {
      return {
        loading: false,
        tagList: [],
      }
    },
    mounted() {
      this.getTags()
    },
    computed: {
      iValue: {
        get () { return this.value; },
        set (v) {
          this.$emit('input', v)
        },
      },
      vid() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
      }
    },
    methods: {
      getTags() {
        this.loading = true;
        this.$store.dispatch('tag/all')
        .then((res) => {
          this.tagList = res
          this.loading = false;
        })
        .catch((err) => {
        })
      },

      addTag(newTag) {
        if (this.disabledAdd)
        {
          Vue.$toast.open({
            message: "You can't add a new tag.",
            type: 'default',
          })
          return true
        }
        const param = {
          name: newTag
        }
        this.$store
          .dispatch('tag/create', param)
          .then((res) => {
            this.tagList.push(res)
            this.value.push(res)
          })
          .catch((err) => {
          })
      },
    }
  }
</script>

<style lang="scss">
</style>